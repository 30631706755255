<template>
  <div>
    <!-- 页头 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>资源上传</el-breadcrumb-item>
    </el-breadcrumb>
    <el-upload
      action=""
      :http-request="fnUploadRequest"
      :show-file-list="true"
      :limit=1
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeUpload"
    >
      <el-button size="small" type="primary" plain><i class="el-icon-upload" />安装脚本上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import oss from '@/network/ossUtils'

export default {
  name: 'ResourceUpload',
  data () {
    return {
    }
  },
  methods: {
    /**
     * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @param    {object}   option [上传选项]
     * @return   {null}   [没有返回]
     */
    fnUploadRequest (option) {
      oss.ossUploadFile(option, 'shell', (fileName) => {
      });
    },
    // 上传成功后
    handleSuccess (response, file, fileList) {
      this.$message.success('上传成功');
    },
    // 上传失败
    handleError (err, file, fileList) {
      console.log(err, file)
      this.$message.error(err);
    },
    // 上传前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 <= 10;

      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isLt2M;
    }
  }
}

</script>
<style scoped>
</style>
